import { template as template_ee212c2bc71c464d9f0b98ae9fcd9e5a } from "@ember/template-compiler";
const SidebarSectionMessage = template_ee212c2bc71c464d9f0b98ae9fcd9e5a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
