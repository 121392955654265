import { template as template_876132dc248a4ceeab335703c6da6940 } from "@ember/template-compiler";
const FKText = template_876132dc248a4ceeab335703c6da6940(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
